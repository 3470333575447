@font-face {
  font-family: 'Roboto';
  src: url('/public/font/roboto-regular-webfont.woff2');
}

:root {
  --white-back: linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%);
  --light-grey: #E9E9E9;
}

@font-face {
  font-family: 'Libre Franklin';
  src: url('/public/font/LibreFranklin-VariableFont_wght.woff');
}

body, html {
  margin: 0;
  /*font-family: Arial, Helvetica, sans-serif;*/
  font-family: Roboto;
  font-size: 16px;
}

@media screen and (max-width: 450px) {
  body, html {
    font-size: 16px;
  }
}

html{
  margin-top:0;
  padding-top:0;
}

body{
  margin-top:0;
  padding-top:0;
  min-height: 100%;
  background-color: #fff;
  /*background: var(--white-back);*/
  overflow-y: scroll;
}

.no-scroll {
  overflow: hidden;
}

* {
  box-sizing: border-box;
  cursor: default;
}

main {
  position: relative;
   /*background-color: #1d1e21;*/
}

main {
  min-height: 100vh;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a.underline {
  text-decoration: underline;
}

a > img {
  cursor: pointer;
}

ul {
  list-style: none;
}

h1, h2, h3, h4 {
  margin: 0px;
  color: #000;
  /*text-shadow: 1px 1px 1px #111;*/
  letter-spacing: normal;
}

h1.spacing_bottom--lrg, h2.spacing_bottom--lrg, h3.spacing_bottom--lrg, h4.spacing_bottom--lrg {
  margin-bottom: 32px;
}

h1.spacing_bottom--med, h2.spacing_bottom--med, h3.spacing_bottom--med, h4.spacing_bottom--med {
  margin-bottom: 24px;
}

h1.spacing_bottom--sml, h2.spacing_bottom--sml, h3.spacing_bottom--sml, h4.spacing_bottom--sml {
  margin-bottom: 16px;
}

div.spacing_top--lrg {
  margin-top: 32px;
}

div.spacing_top--med {
  margin-top: 24px;
}

div.spacing_bottom--lrg {
  margin-bottom: 60px;
}

div.spacing_bottom--med {
  margin-bottom: 32px;
}

div.spacing_bottom--sml {
  margin-bottom: 16px;
}

a.spacing-sml {
  margin: 16px;
}

h1 {
  font-size: 3.5rem;
}

@media (max-width: 767px) {
  h1 {
    font-size: 2.5rem;
  }
}

h1.white, h2.white, h3.white, h4.white {
  color: #ffffff;
}

h2 {
  font-size: 3rem;
}

@media (max-width: 767px) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: 1.5rem;
}

@media (max-width: 767px) {
  h3 {
    font-size: 1rem;
  }
}

p {
  font-size: 16px;
  margin-top: 0em;
  margin-bottom: 0em;
  color: #080808;
}

.is-visible-block {
  display: block;
}

.is-visible-flex {
  display: flex
}

img {
  max-width: 100%;
  max-height: 100%;
}

.text-center {
  text-align: center;
}

ul {
  margin: 0;
  padding: 0;
}
/*
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}*/


/*SPLASH------------------------------------------------*/

.homepage-video-container {
  max-width: 1000px;
  pointer-events: none;
}

.homepage-video-container video {
  width: 100%;
}

/*.splash {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 100%;
}*/

.splash-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*background-image: url('/public/images/gallery/Sequence 04.gif');*/
  background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url('/public/images/hpbg1.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.splash {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.splash-image {
  display: none;
  width: 90%;
  max-width: 600px;
  margin: 0px auto 32px;
}

.splash-content {
  position: absolute;
  bottom: 50px;
}

@media screen and (max-width: 767px) {
  .splash-bg {
    /*background-image: url('/public/images/gallery/Sequence 03 Copy 01.gif');*/
    background-image: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url('/public/images/hpbg1.png');
    background-position: 10% 50%;
  }
}

.splash h1, .splash h4 {
  color: #fff;
}

/*NAVBAR------------------------------------------------*/

.overlay {
  position: fixed;
  opacity: 0;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s;
}

.overlay.active {
  opacity: 1;
  transition: opacity 0.3s;
  pointer-events: all;
}

header {
  width: 100%;
	height: 50px;
	position: fixed;
	z-index: 5;
  top: 0;
}

#navbar {
	width: 100%;
	height: 50px; 
	position: relative;
  transition: transform 0.3s;
}

#navbar.white {
  background-color: transparent;
}

#navbar.collapse {
  transform: translateY(-100%);
}

#navbar.black {
   background-color: #000000;
}

#navbartop {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #000000;
  /*background-color: #ffffff;*/
}

/*
#navbar.black #navbartop {
  background-color: transparent;
}
*/

#navbar.home  #navbartop{
  background-color: transparent;
}

#navbartitle {
  position: relative;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#navbartitle .link {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  /* padding: 10px 0px; */
}

#navbarlogo {
  position: relative;
  width: 100%;
  max-width: 125px;
  filter: invert(100%);
  transition-duration: 0.3s;
}

#navbarlogo.slide-in {
  transform: translate(0px, -100%);
  transition-duration: 0.3s;
}

#navbarlogo2 {
  position: absolute;
  height: 100%;
  filter: invert(100%);
  transform: translate(0px, 100%);
  transition-duration: 0.3s;
}

#navbarlogo2.slide-in {
  transform: translate(0px, 0px);
  transition-duration: 0.3s;
}

#navbarlogo.black {
	filter: invert(1);
}

#navbarlogo2.black {
	filter: invert(1);
}

#navbarlogo.white {
	filter: none;
}

#navbarlogo2.white {
	filter: none;
}

.socialicons {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#hamburgerlinks .socialicons {
  width: 100%;
  padding: 10px 20px;
  font-size: 20px;
}

.socialiconlinks {
  width: 30px;
  height: 30px;
  margin: 0px 10px;
  display: flex;
}

#hamburgerlinks .socialiconlinks {
  margin: 0px 20px;
}

.socialiconlinks img {
  width: 100%;
  /*filter: invert(100%);*/
  transition: filter 0.3s;
}

.socialiconlinks img.black {
	filter: none;
}

#hamburger {
  width: 20%;
  overflow: visible;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 10px;
  transition: 0.5s ease-out;
}

#hamburger span {
  color: white;
  padding: 10px 0px 10px 10px;
}

/*
#hamburger:after {
  content: "MENU";
  color: white;
  padding: 10px 0px 10px 10px;
}
*/

#account {
  width: 20%;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 20px;
  transition: 0.5s ease-out;
}

#hamburger.slide-out {
  transform: translateX(100%);
}

#hamburger-icon {
  width: 35px;
  filter: invert(100%);
  /*animation: 3s ease-in-out infinite spin;*/
}

#hamburger-icon2 {
  width: 35px;
  filter: invert(100%);
  /*animation: 3s ease-in-out infinite spin;*/
  z-index: 1;
  cursor: pointer;
}

#hamburger-icon.white, #hamburger-icon2.white {
  filter: none;
}

.hamburger-text {
  font-size: 12px;
  color: #fff;
  position: absolute;
  bottom: -12px;
  width: 35px;
  text-align: center;
}

@keyframes spin {
  0%   {rotate: 0deg;}
  100%  {rotate: 359deg;}  
}

#hamburger-icon.black {
	filter: invert(1);
}

#hamburger-icon.white {
	filter: none;
}

#hamburger.dark {
  filter: invert(0%);
  transition: filter 0.3s;
}

#flyoutlinks {
  flex-direction: column;
  display: flex;
  width: 100%;
}

.nav-content-wrap {
  /*max-height: 0px;*/
  overflow: hidden;
  z-index: 1;
  position: relative;
   /*background-color: #1d1e21;*/
  transition: transform 0.5s;
}

.nav-content-wrap.white {
  background-color: #ffffff;
}

.nav-content-wrap.black {
  background-color: transparent;
}

.nav-content-wrap.white .app-icon {
  filter: none;
}

.nav-content-wrap.white .app-name {
  color: #000000;
}

@media screen and (min-width: 769px) {
  .nav-content-wrap {
    display: none;
  }
}

.nav-content-wrap.home {
  max-height: 0px;
  overflow: hidden;
  background-color: transparent;
}

.nav-content-wrap.collapse {
  transform: translateY(-150%);
}

.nav-content-wrap.black.collapse {
  transform: translateY(-200%);
}

.flyoutlinks {
  display: block;
  width: 100%;
  padding: 10px 50px 10px 0px;
  text-align: left;
  color: black;
  font-family: 'Libre Franklin';
}

.apps-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  width: 90%;
  margin: 0px auto 16px;
}

.apps-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 12px;
}

.app {
  width: 25%;
  height: 0px;
  padding-bottom: 25%;
  position: relative;
}

@media screen and (min-width: 500px) {
  .app {
    width: 20%;
    padding-bottom: 20%;
  }
}

@media screen and (min-width: 600px) {
  .app {
    width: 16.66%;
    padding-bottom: 16.66%;
  }
}

.app.disabled {
  opacity: 0.2;
  pointer-events: none;
}

.app-wrap {
  position: absolute;
  width: 80%;
  height: 80%;
  /*border: 1px solid #fff;
  border-radius: 20%;*/
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.app .app-wrap.active {
  /* outline: 1px solid #fff; */
  border-radius: 12px;
  /* background-color: #fff; */
  /* opacity: 0.1; */
}

.app .app-wrap.active .app-name {
  text-decoration: underline;
}

.app-icon {
  height: 70%;
  display: flex;
  justify-content: center;
  filter: invert(100%);
  padding: 5px;
}

.app-name {
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.75rem;
}

#navbar-lrg {
  position: relative;
  display: none;
  width: 100%;
}

#logo--lrg, #menu--lrg {
  height: 50px;
  width: 10%;
  display: flex;
  justify-content: start;
  align-items: center;
  min-width: 120px;
}

.menu--lrg-wrap {
  max-width: 50px;
  display: flex;
  margin-left: 20px;
  position: relative;
}

/*
.menu--lrg-wrap::after {
  content: "MENU";
  left: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  padding: 10px 0px 10px 10px;
  font-size: 16px;
  z-index: 1;
  cursor: pointer;
  color:
}
*/

#navbar.black .menu--lrg-wrap::after {
  color: #fff;
}

#navbar.home .menu--lrg-wrap::after {
  color: #fff;
}

.logo--lrg-wrap {
  display: flex;
}

.logo--lrg-wrap .link {
  display: flex;
}

#account--lrg {
  height: 50px;
  width: 10%;
  display: flex;
  justify-content: end;
  align-items: center;
  min-width: 120px;
}

.account--lrg-wrap {
  max-width: 50px;
  display: flex;
  margin-right: 20px;
}

.navbar-links-wrap--lrg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 80%;
}

.navbar-links--lrg {
  color: #080808;
  padding: 5px 10px;
  margin: 0px 20px;
  cursor: pointer;
  font-weight: bold;
  font-family: 'Libre Franklin';
  display: none;
}

.navbar-links--lrg.active {
  text-decoration: none;
}

.navbar-lrg-flyout {
  background-color: #fff;
  /*background: var(--white-back);*/
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100vh;
  transition: ease 0.4s all;
  padding: 70px 20px 10px;
  transform: translate(-100%, 0);
}

.navbar-lrg-flyout.black {
  background-color: #000000;
}

.navbar-lrg-flyout.open {
  transform: translate(0%, 0);
  box-shadow: 0 8px 16px 0 hsla(0,0%,8%,.2);
}

#navbar.home .navbar-lrg-flyout {
  background-color: transparent;
}

#navbar.home .navbar-lrg-flyout.open {
  transform: translate(0%, 0);
  box-shadow: none;
} 

#navbar.home .flyoutlinks {
  color: #fff;
}

#navbar.black .flyoutlinks {
  color: #fff;
}

.navbar-links--lrg.hp {
  color: white;
  background-color: transparent ;
  padding: 5px 10px;
  margin: 0px 20px;
  cursor: pointer;
  letter-spacing: 2px;
  /*border: 2px solid white;*/
  transition: 0.3s ease;
  border-radius: 20px;
}

@media screen and (min-width: 769px) {
  #navbartop {
    display: none;
  }
  #navbar-lrg {
    display: flex;
  }
}

a.navbar-links--lrg.hp:hover {
  background-color: #fff;
  color: #000000;
  border: 2px solid #fff;
  font-weight: bold;
}

/*ALL PAGES-------------------------------------------------------------------*/

.content-wrap {
  padding: 32px 10px;
}

.content-wrap.top {
  padding: 82px 10px;
}

.content-wrap.black {
  background-color: #000000;
}

/*
.content-wrap.bgc1 {
  background: var(--white-back);
}
*/

.content-wrap.bgc1 {
  background: linear-gradient(0deg,#d5d4d0 0,#d5d4d0 1%,#eeeeec 31%,#efeeec 75%,#e9e9e7);
  background: var(--white-back);
  background: linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.3)),url(https://belucky.band/static/media/hpbg1.960cc42cf7e7312f4be7.png);
  background-size: cover;
  background-position: 50% 50%;
}

.cta {
  display: block;
  padding: 10px 20px;
  background-color: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 700;
  line-height: 1;
  color: #000;
  cursor: pointer;
  letter-spacing: 1px;
  transition: 0.3s all;
}

.cta:hover {
  -webkit-box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.55);
  box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.55);
}

.cta.victoria25 {
  background-color: black;
  color: #fff;
}

.cta.black {
  background-color: black;
  color: #fff;
  border: none;
}

.cta.black.victoria25:hover {
  color: rgb(254, 191, 81);
}

.cta.center {
  display: block;
  padding: 10px 20px;
  background-color: #ffffff;
  width: fit-content;
  margin: 16px auto;
  font-weight: 700;
  line-height: 1;
  color: #000;
  cursor: pointer;
  letter-spacing: 1px;
}

.cta:hover {
  -webkit-box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.55);
  box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 0.55);
  background-color: black;
  color: #ffffff;
}

.shadow {
  -webkit-box-shadow: 2px 2px 10px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 10px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 10px -8px rgba(0, 0, 0, 0.75);
}

.about.content-wrap {
  max-width: 800px;
  margin: auto;
}

/*TUNAGE----------------------------------------------------------------*/

main#main-tunage {
  width: 100%;
}

@media screen and (max-width: 767px) {
  main#main-article {
    padding-top: 50px;
  }
}

.fullpage-swiper {
  height: 100%;
}

.fullpage-slide {
  width: 100%;
  height: 100%;
  display: flex;
  /*justify-content: center;
  align-items: center;*/ 
}

.fullpage-bgimage-wrap {
  width: 100%;
  /*height: var(--full-screen-height);*/
  height: 600px;
  position: relative;
  background-size: cover;
  background-position: center;
}

.fullpage-bgimage-wrap.tunage {
  /*background-image: url('/public/images/full_band_2.JPG');*/
  background-image: url('/public/images/disco on neptune artwork final.jpg');
}

.fullpage-bgimage-wrap.tunage.img2 {
  background-image: url('/public/images/gallery/PICT1222.jpeg');
  max-height: 1000px;
}

.fullpage-bgimage-wrap.tunage.img3 {
  background-image: url('/public/images/gallery/PICT1130-2.JPG');
  max-height: 1000px;
}

@media (max-width: 767px) {
  .fullpage-bgimage-wrap.tunage.img2 {
    background-image: url('/public/images/gallery/PICT1222-mb.jpeg');
  }

  .fullpage-bgimage-wrap.tunage.img3 {
    background-image: url('/public/images/gallery/PICT1130.JPG');
  }
}

.tunage-header {
  position: relative;
  top: 65%;
  margin: 0px 0px 24px;
}

.content-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-container-copy {
  margin: 20px;
}

@media screen and (max-width: 767px) {
  .content-container {
    flex-direction: column;
  }
  .content-container-copy {
    margin: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.fullpage-poster-image {
  max-width: 400px;
}

.teaser-image {
  max-width: 500px;
  margin: auto;
}


/*CPOD------------------------------------------------------------------------------*/

#main-cpod .content-wrap {
  position: fixed;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
}

.c-pod-wrap {
  width: 100%;
  max-width: 410px;
  height: 480px;
  position: relative;
  top: 45%;
  transform: translate(0, -50%);
  margin: auto;
}

.c-pod-wrap div {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}

.c-pod {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10%;
  padding: 30px 25px;
  background-color: lightblue;
  box-shadow: inset 0px 0px 11px -2px rgba(0,0,0,0.55);
}

.c-pod-inner {
  height: 100%;
}

.c-pod-screen-outer {
  height: 50%;
  background-color: #000;
  padding: 6px 10px;
  border-radius: 8px;
}

.c-pod-screen-inner {
  height: 200px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-pod-screen-menu {
  width: 50%;
  height: 100%;
  flex: 0 0 50%;
}

.c-pod-screen-menu-items {
  height: calc(100% - 12px);
  overflow: scroll;
}

.c-pod-screen-menu-items::-webkit-scrollbar {
  display: none;
}

.c-pod-screen-menu-items.collapsed {
  display: none;
}

.c-pod-screen-name {
  background-color: silver;
  font-size: 10px;
  display: flex;
  align-items: center;
  height: 12px;
  padding: 0px 5px;
}

.c-pod-screen-info {
  display: flex;
  background-color: silver;
  font-size: 10px;
  padding: 2px 5px;
  justify-content: space-between;
  align-items: center;
}

.c-pod-screen-battery-icon {
  width: 15px;
  height: 8px;
  background-color: lightgreen;
}

.c-pod-screen-menu-item {
  padding: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.c-pod-screen-menu-item.active {
  background-color: #007AFF;
  color: #ffffff;
}

.c-pod-screen-art {
  width: 50%;
  height: 100%;
  flex: 0 0 50%;
}

img.c-pod-screen-image {
  height: 100%;
  object-fit: cover;
}

.c-pod-body {
  height: 50%;
  width: 100%;
  padding-top: 15px;
}

.c-pod-wheel-wrap {
  height: 100%;
  aspect-ratio: 1/1;
  margin: auto;
}

.c-pod-wheel-outer {
  border-radius: 50%;
  height: 100%;
  background-color: white;
  position: relative;
}

.c-pod-wheel-inner {
  width: 50%;
  height: 50%;
  border-radius: 50%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: lightblue;
}

.c-pod-wheel-inner.pressed {
  -webkit-box-shadow: inset 0px 0px 5px 1px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: inset 0px 0px 5px 1px rgba(0, 0, 0, 0.55);
  box-shadow: inset 0px 0px 5px 1px rgba(0, 0, 0, 0.55);
}

.c-pod-wheel-outer.pressed-left {
  -webkit-box-shadow: inset 1px 0px 2px 0px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: inset 1px 0px 2px 0px rgba(0, 0, 0, 0.55);
  box-shadow: inset 1px 0px 2px 0px rgba(0, 0, 0, 0.55);
}

.c-pod-wheel-outer.pressed-right {
  -webkit-box-shadow: inset -1px 0px 2px 0px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: inset -1px 0px 2px 0px rgba(0, 0, 0, 0.55);
  box-shadow: inset -1px 0px 2px 0px rgba(0, 0, 0, 0.55);
}

.c-pod-wheel-outer.pressed-down {
  -webkit-box-shadow: inset 0px -1px 2px 0px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: inset 0px -1px 2px 0px rgba(0, 0, 0, 0.55);
  box-shadow: inset 0px -1px 2px 0px rgba(0, 0, 0, 0.55);
}

.c-pod-wheel-outer.pressed-up {
  -webkit-box-shadow: inset 0px 1px 4px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset 0px 1px 4px 0px rgba(0, 0, 0, 0.75);
  box-shadow: inset 0px 1px 4px 0px rgba(0, 0, 0, 0.75);
}

.c-pod-wheel-back, .c-pod-wheel-play, .c-pod-wheel-next, .c-pod-wheel-menu {
  position: absolute;
}

.c-pod-wheel-back {
  left: 5px;
  top: 50%;
  transform: translate(0, -50%);
}

.c-pod-wheel-next {
  right: 5px;
  top: 50%;
  transform: translate(0, -50%);
}

.c-pod-wheel-play {
  left: 50%;
  bottom: 15px;
  transform: translate(-50%, 0);
}

.c-pod-wheel-menu {
  left: 50%;
  top: 15px;
  transform: translate(-50%, 0);
}

.discog-slider-wrap {
  position: relative;
  max-width: 1200px;
  margin: 0px auto;
}

.ep-title {
  font-weight: 700;
  text-align: center;
  color: #000;
  cursor: pointer;
}

.record-swiper-wrapper {
  margin: auto;
}

.record-swiper-wrapper .swiper-wrapper {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.hero {
  background-image: url('/public/images/full_band_old.jpg');
  min-height: 100%;
  height: 100vh;
}

.bgimg-2 {
  background-image: url('/public/images/full_band_2.JPG');
  min-height: 400px;
  height: 400px;
}

.stream-links-wrapper {
  margin: 16px 0 40px;
  width: 100%;
}

.stream-links {
  height: 100%;
  opacity: 1;
}

.stream-links .socialicons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.stream-links .socialiconlinks img {
  filter: invert(1);
}

.box-slide img {
  min-width: 100%;
}

@media screen and (min-width: 769px) {
  .recent-release-image {
    max-width: 1200px;
    margin: auto;
  }
}

.next-show-wrap {
  margin-bottom: 32px;
}

.poster-wrap {
  max-width: 500px;
  height: 500px;
  margin: auto;
  overflow: hidden;
  background-image: url('/public/images/100-club.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.latest-releases-wrap {
  margin: 0 0 40px;
}

.youtube-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

@media screen and (max-width: 768px) {
  .utube-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
  }

  .youtube-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 769px) {
  .utube-wrapper {
    width: 780px;
    height: calc(780px * (9/16));
    margin: auto;
  }
  
  .youtube-iframe {
    position: relative;
  }
}


/*SHOWS---------------------------------------------------------------------------*/

.calendar-switch-wrap {
  width: 100%;  
}

#calendar-wrapper {
  position: relative;
  margin-bottom: 16px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
  max-width: 450px;
  margin: 0 auto;
}

.current-selected-date {
  color: black;
}

@media screen and (min-width: 769px) {
  .current-selected-date {
    display: none;
  }
}

#calendar {
  margin: auto;
}

#list-details-wrap {
  margin-top: 32px;
}

.next-month, .previous-month {
  position: absolute;
  font-size: 50px;
  cursor: pointer;
  color: black;
}

.next-month {
  top: 45px;
  right: 0;
}

.previous-month {
  top: 45px;
  left: 0;
}

.previous-month.disabled, .next-month.disabled {
  pointer-events: none;
  color: darkslategrey;
}

.months-title {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin: 0px 0px 8px;
  color: black;
}

.months {
  display: none;
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
}

.month {
  display: inline-block;
  width: calc(100% / 12);
}

th.days-of-week {
  width: calc(100% / 7);
  text-align: center;
  color: black;
  min-width: 34px;
}

.month.selected {
  color: black;
  font-weight: bold;
  background-color: red;
}

td[title="today"] {
  font-weight: bold;
  text-decoration: underline;
}

.dates {
  cursor: pointer;
  color: black;
  text-align: center;
}

.dates.active-show, .dates.selected {
  color: #000;
  font-weight: bold;
}

.dates.active-show {
  background-color: #b8b8b8;
}

.dates.selected {
  background-color: #66a9d6;
}

.content-wrap.calendar-content-wrap {
  padding: 10px;
  margin: 0px auto;
  max-width: 700px;
}

.poster-page.calendar-pages {
  display: none;
}

.sticky-poster {
  position: sticky;
  top: 102px;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.sticky-poster h2 {
  font-family: 'Libre Franklin';
}

.sticky-poster img {
  max-width: 400px;
}

@media screen and (min-width: 1000px) {
  .poster-page.calendar-pages {
    display: block;
  }

  .content-wrap.calendar-content-wrap {
    display: flex;
    max-width: unset;
  }

  .calendar-switch-wrap {
    display: none;
  }

  .calendar-page {
    display: none;
  }

  .calendar-pages {
    max-width: 600px;
    width: 50%;
  }

  .months-title {
    font-size: 1.5rem;
  }

}

.show-pillbox {
  border-radius: 10px;
  /*border: 1px solid black;*/
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  overflow: hidden;
  position: relative;
  align-items: stretch;
  background-color: white;
}

.show-pillbox.hidden {
  display: none;
}

h3.month-heading {
  display: none;
  color: #080808;
}

h3.month-heading:not(h3:first-of-type) {
  margin-top: 24px;
}

.show-date {
  position: relative;
  width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*border-right: 1px solid black;*/
  flex-direction: column;
}

span.show-date-number {
  font-size: 2rem;
}

.show-main-container {
  display: flex;
  width: calc(100% - 85px);
  flex-direction: column;
}

.show-name {
  width: 100%;
  /*border-bottom: 1px solid black;*/
  padding: 5px;
  background-color: powderblue;
  flex-shrink: 0;
}

.show-details-container {
  width: 100%;
  display: flex;
}

.show-details {
  width: 55%;
  padding: 5px;
}

.show-poster {
  margin: auto;
  text-decoration: underline;
  font-size: 12px;
}

.show-buy-now {
  margin: auto;
  font-weight: bold;
}

main#main-shows {
  margin: 0px auto;
  max-width: 1200px;
}

.calendar-switcher {
  display: flex;
  justify-content: center;
}

.list-switch, .calendar-switch {
  width: 80px;
  position: relative;
  margin: 0px 8px;
  opacity: 0.55;
  padding: 8px 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.list-switch-icon, .calendar-switch-icon {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 30px;
  max-height: 30px;
  margin: auto auto 4px;
}

.list-switch.enabled, .calendar-switch.enabled {
  opacity: 1;
  /*-webkit-box-shadow: 0px 2px 2px #c1c1c1;
  -moz-box-shadow: 0px 2px 2px #c1c1c1;
  box-shadow: 0px 2px 2px #c1c1c1;*/
}

.list-switch-title, .calendar-switch-title {
  font-size: 12px;
  text-align: center;
  color: #000000;
}

.list-switch.enabled .list-switch-title, .calendar-switch.enabled .calendar-switch-title {
  text-decoration: underline;
  color: #000000;
}

.show-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.9);
  z-index: 5;
}

.show-modal-image {
  width: 80%;
  max-width: 400px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.show-modal-close {
  position: absolute;
  top: -30px;
  right: 0;
  color: #ffff;
  font-size: 30px;
  cursor: pointer;
}

.show-modal-buy-tickets {
  display: block;
  color: #fff;
  font-weight: 700;
  width: -moz-fit-content;
  width: fit-content;
  padding: 12px 24px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  margin: 16px auto;
  background-color: #77C3FF;
}

.show-venue {
  display: none;
}


/* SHOWS 2 ----------------------------------------------------------------------*/

.show-promo-fullpage {
  max-width: 600px;
  margin: auto;
}

.show-promo-fullpage-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.show-promo-image {
  max-width: 400px;
}

/* NEWS -------------------------------------------------------------------------*/

main#main-news {
  width: 100%;
}

.news-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1300px;
  margin: 20px auto 0px;
}

.news-container {
  width: 33.33%;
  margin-bottom: 10px;
}

.news-container-inner {
  margin: 0px 10px;
}

.news-cover {
  height: 300px;
  overflow: hidden;
}

.news-cover img {
  object-fit: cover;
  min-height: 100%;
  min-width: 100%;
}

.news-content-wrap {
  padding: 8px;
}

.news-content-header {
  margin: 0px 0px 8px;
  color: #080808;
}

.news-content-readmore {
  display: block;
  margin: 8px 0px 0px;
  font-size: 12px;
  color: black;
}

.news-content-readmore::after{
  content: "\2192";
  padding-left: 4px;
}

@media screen and (max-width: 1000px) {
  .news-container {
    width: 50%;
  }
}

@media screen and (max-width: 700px) {
  .news-container {
    width: 100%;
    max-width: 450px;
    margin: 0px auto 16px;
  }
}


/*ARTICLE PAGES*/

main#main-article {
  width: 100%;
  padding-top: 0;
}

@media screen and (max-width: 767px) {
  main#main-article {
    width: 100%;
    padding-top: 50px;
  }
}

.fullpage-bgimage-wrap.article001 {
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-content-wrapper {
  max-width: 800px;
  margin: 0 auto;
}

.article-content {
  padding: 24px 20px;
}

.article-publish-date {
  font-style: italic;
  font-size: 14px;
}

/* Contact Form -----------------------------------------------------------------*/

#main-contact {
  background-color: #000000;
  background-image: url("/public/images/liveblack.png");
  background-size: cover;
}

.contact-form-container, .contact-wrap {
  display: flex;
  justify-content: center;
  max-width: 500px;
  margin: auto;
  padding: 24px 0px;
}

.contact-form-container.top {
  padding-top: 50px;
}

@media screen and (max-width: 520px) {
  .contact-form-container {
    margin: 10px;
  }
}

form#contact-form {
  width: 100%;
}

form h1, form div, form input, form textarea {
  color: #fff;
}

.contact-form.form-field {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 16px;
  color: inherit;
}

.contact-form.form-field input, .contact-form.form-field textarea {
  width: 100%;
  border: none;
  border-bottom: 1px solid lightgray;
  font-size: 16px;
  padding: 8px 0px;
  border-radius: 0px;
  background: transparent;
}

.contact-form.form-field textarea {
  font-family: inherit;
}

.contact-form.form-field label {
  margin-bottom: 4px;
}

.contact-form input:focus-visible, .contact-form textarea:focus-visible, textarea:focus {
  outline: none;
  border-bottom: 1px solid slategray;
}

textarea {
  resize: none;
}

input.form-submit {
  display: block;
  margin: auto;
  padding: 10px 20px;
  border: none;
  font-weight: 700;
  line-height: 1;
  background-color: whitesmoke;
  transition: 0.2s all;
  color: black;
  cursor: pointer;
  letter-spacing: 1px;
  transition: 0.2s all;
  font-size: 16px;
  width: 100%;
  border: 2px solid #7c7c7c;
  border-top-color: #fff;
  border-left-color: #fff;
}

@media screen and (max-width: 767px) {
  input.form-submit {
    width: fit-content;
  }
}

input.form-submit:hover {
  background-color: lightgray;
}

input.form-submit:disabled, input.form-submit:disabled:hover {
  background-color: whitesmoke;
  color: lightgray;
}

.contact-form.form-field.error-field {
  color: red;
  font-size: 12px;
}

.contact-form.form-field.error-field.hidden {
  display: none;
}

::placeholder {
  color: lightgray;
}






/* PLP ---------------------------------------------------------------------------*/

.lister {
  display: flex;
  flex-wrap: wrap;
}

.product-tile {
  width: 50%;
  flex: 0 0 auto;
  padding: 8px;
  color: black;
}

@media screen and (min-width: 769px) {
  .product-tile {
    width: 25%;
  }
}

.product-image {
  display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
}

.product-image img {
  width: 100%;
  height: 100%;
}

.product-name, .product-price {
  text-align: center;
}

.product-price {
  font-size: 0.875rem;
}


/*PDP-------------------------------------------------------------------------*/

.product-image-container {
  width: 80%;
  margin: auto;
}

.product-alt-image-container {
  height: 65px;
  margin: 5px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-alt-image {
  height: 100%;
  width: 50px;
  margin: 0px 5px;
  display: flex;
  justify-content: center;
}

.product-details .product-name, .product-details .product-price {
  text-align: left;
}

.product-details .product-name {
  margin: 0px 0px 8px;
  font-size: 2rem;
}

.product-details .product-price {
  margin: 0px 0px 8px;
  font-size: 1.25rem;
}

.add-to-cart {
  margin: 20px 0px;
  padding: 10px 50px;
  background-color: black;
  color: white;
  border: none;
  font-size: 1rem;
}


/*HP Slider-------------------------------------------------------------------*/

.homepage-socials .socialicons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  filter: invert(1);
}

/*Footer---------------------------------------------------------------------------*/

footer.fixed {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

.footer-content {
  padding: 32px;
  background-color: lightgrey;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-block {
  width: 33.33%;
}

.footer-info-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-map-wrap {
  display: flex;
}

.site-map {
  margin-right: 16px;
}

.site-map li a {
  color: black;
}

.footer-socials {
  text-align: center;
}

.footer-social-links {
  display: flex;
  justify-content: center;
}

.footer-social-links .socialicons {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.contact-details {
  color: black;
}

.copyright-notice {
  display: flex;
  justify-content: end;
  width: 100%;
}

.copyright-notice p {
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  .footer-content {
    padding: 20px;
    flex-direction: column;
  }

  .footer-block {
    width: 100%;
  }

  .footer-info-wrap {
    justify-content: center;
  }

  .site-map-wrap {
    display: none;
    flex-direction: column;
  }

  .site-map {
    margin-bottom: 12px;
    margin-right: 0px;
  }

  .site-map h4 {
    text-align: center;
  }

  .site-map h4::after{
    content: "\203A";
    display: inline-block;
    margin-left: 10px;
    rotate: 90deg;
    transition: ease-in-out 0.4s all;
  }

  .site-map h4.open::after{
    rotate: 270deg;
  }

  .site-map ul {
    max-height: 0px;
    overflow: hidden;
    transition: ease-in-out 0.4s all;
    text-align: center;
  }
  
  .footer-socials {
    margin: 12px 0px;
  }

  .footer-socials h3 {
    display: none;
  }

  .footer-socials a.mailto {
    display: block;
    padding-top: 16px;
  }

  .copyright-notice {
    margin-top: 16px;
    justify-content: center;
  }
 
}

/*PROMOTION ----------------------------------------------------------------------*/

.promo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 32px 0px 60px;
  overflow: hidden;
  margin-top: 40px;
}

.promo-bgimg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
 /*background-image: url('/public/images/gallery/PICT1068.JPG');*/
  background-position: center;
  background-size: cover  ;
}

.promo-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;  
  z-index: 1;
}

.promo-content h2 {
  font-size: 6rem;
  font-weight: bold;
  line-height: 1;
}

.promo-content h3 {
  font-size: 2.5rem;
  font-family: 'Libre Franklin';
}

.promo-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.promo img {
  width: 100%;
  height: 100%;
}




.hidden {
  display: none;
}